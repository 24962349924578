$(document).ready(function(){

    $('.owl-carousel.full-page-carousel').owlCarousel({
        items: 1,
        nav: true,
        margin: 0,
        autoplay: true,
        autoplayTimeout: 5000,
        loop: true,
        navText: ["<img src='dist/img/arrow.svg' class='owl-prev'>","<img src='dist/img/arrow.svg' class='owl-next'>"],
    });

});

$(document).on('click', '#mobileMenu .carousel-link', function(e) {
    $('#mobileMenu').modal('hide');
});