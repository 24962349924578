function setCookie(key, value, expiry) {
        var expires = new Date();
        expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
        document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
    }

    function getCookie(key) {
        var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
        return keyValue ? keyValue[2] : null;
    }

    function eraseCookie(key) {
        var keyValue = getCookie(key);
        setCookie(key, keyValue, '-1');

}

$(function() {
   
	$( ".cookies #acceptCookies" ).on( "click", function() {
	  setCookie('cookies_settings','true','30');
	  $('.cookies').fadeOut(500);
	});


	$( ".cookies #cookies_close" ).on( "click", function() {
	  $('.cookies').fadeOut(500);
	});


	if (getCookie('cookies_settings') != 'true') {
		$('.cookies').removeClass("d-none");
	}

}); 