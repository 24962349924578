import 'bootstrap';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
var SimpleLightbox = require('simple-lightbox');


import './owlCarousel.js';
import './cookies.js';


$(window).on('load resize', function () {

	var navbarHeight = $('.top-nav').outerHeight() + $('#mainNav').outerHeight();
	$(".fs-page").css('padding-top', navbarHeight + 'px');
	$(".fs-page-container").css('margin-top', '-' + navbarHeight + 'px');

	if ($(window).width() > 991) {
		$(".fs-img, .fs-text").css('min-height', 'calc(100vh - ' + navbarHeight + 'px)');
	} else {
		$(".fs-img, .fs-text").css('min-height', 'auto');
	}
});


var SimpleLightbox = require('simple-lightbox');

SimpleLightbox.registerAsJqueryPlugin($);

$('#products-gal a').simpleLightbox();
